import { gql, useMutation } from '@apollo/client';
import { Form as AntForm, App, Dropdown, Input, message, Modal } from 'antd';
import { useClientContext } from 'components/client-context-provider';
import { NuButton } from 'components/nuspire';
import * as NuIcon from 'components/nuspire/nu-icon';
import { FormikInput } from 'components/shared-components';
import { Formik, useField } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { client } from 'utils/graphql';

const RENAME_WIDGET = gql`
  mutation RenameWidget($id: String!, $input: UpdateWidgetInput!) {
    updateWidget(id: $id, input: $input) {
      id
      name
      description
    }
  }
`;

const WIDGET_SEARCH = gql`
  query WidgetSettingsSearch($clientId: String!, $queryString: String, $widgetSlug: String) {
    widgetSearch(clientId: $clientId, queryString: $queryString, size: 100, widgetSlug: $widgetSlug) {
      items {
        id
        name
        widgetSlug
        description
        # server side widget definition.
        widgetDefinition {
          id
          name
          description
        }
      }
      next
      total
    }
  }
`;

const { TextArea } = Input;

function DescriptionInput() {
  const [{ value, onBlur }, { touched, error }, { setValue }] = useField({
    name: 'description',
  });

  return (
    <AntForm.Item label="Description">
      <TextArea name="description" value={value} onChange={(e) => setValue(e.target.value)} onBlur={onBlur} />
    </AntForm.Item>
  );
}

function RenameWidgetModal(props: {
  visible: boolean;
  onCancel: () => void;
  id: string;
  description?: string;
  name?: string;
  widgetSlug?: string;
}) {
  const { visible, onCancel, id, name, widgetSlug, description } = props;
  const [renameWidget] = useMutation(RENAME_WIDGET);
  const { clientId } = useClientContext();
  const { modal } = App.useApp();

  const handleSubmit = async (args: { name?: string; widgetSlug?: string }) => {
    const rename = async () => {
      await renameWidget({
        variables: { id, input: args },
      });
    };
    //query widgets by clientId
    const { data } = await client.query({
      query: WIDGET_SEARCH,
      variables: { clientId, widgetSlug, queryString: args.name },
      fetchPolicy: 'network-only',
    });
    const matchingName = data?.widgetSearch?.items.find((item) => {
      return item.name === name;
    });

    if (matchingName) {
      return modal.confirm({
        onOk: rename,
        title: 'The Same Widget Name Already Exists',
        content: `Would you like to add this widget anyway "${name}"`,
      });
    }

    return rename();
  };

  return (
    <Modal open={visible} title="Rename Widget" onCancel={onCancel} footer={false}>
      <Formik
        initialValues={{
          name,
          description,
        }}
        onSubmit={async (values, helpers) => {
          await handleSubmit(values);

          helpers.resetForm({ values: { name: values.name, description: values.description } });

          onCancel();
        }}
      >
        {({ submitForm, isSubmitting, errors, dirty }) => (
          <AntForm layout="vertical" onFinish={() => submitForm()}>
            <FormikInput name="name" label="Name" required />
            <DescriptionInput />
            <AntForm.Item>
              <NuButton
                type="primary"
                htmlType="submit"
                disabled={!dirty || Object.keys(errors).length > 0 || isSubmitting}
                loading={isSubmitting}
              >
                Submit
              </NuButton>
            </AntForm.Item>
          </AntForm>
        )}
      </Formik>
    </Modal>
  );
}

const DELETE_WIDGET = gql`
  mutation DeleteWidget($id: String!) {
    deleteWidget(id: $id) {
      id
      deleted
    }
  }
`;

export function WidgetSettingsButton(props: { id: string; name?: string; widgetSlug?: string; description?: string }) {
  const { clientId } = useClientContext();
  const { id, name, widgetSlug, description } = props;
  const [renaming, setRenaming] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const handleRename = () => setRenaming(true);
  const handleCloseRenaming = () => setRenaming(false);
  const [deleteWidget, deleteResults] = useMutation(DELETE_WIDGET);

  const navigate = useNavigate();

  const handleDeleteClick = () => {
    setDeleting(true);
  };
  const handleDeleteWidget = async () => {
    try {
      // delete widget
      await deleteWidget({
        variables: { id },
      });

      // success message
      message.success('Widget has been deleted.');

      // push to dashboard.
      navigate(`/${clientId}`);
    } catch (err) {
      message.error('There was a problem deleting this widget');
    }
  };

  return (
    <>
      <Dropdown
        trigger={['click']}
        placement="bottomRight"
        menu={{
          items: [
            {
              key: 'rename',
              onClick: handleRename,
              label: 'Rename',
            },
            {
              key: 'delete',
              onClick: handleDeleteClick,
              label: 'Delete',
              danger: true,
            },
          ],
        }}
      >
        <NuButton size="large" type="text">
          <NuIcon.SettingsIcon />
        </NuButton>
      </Dropdown>

      <RenameWidgetModal
        visible={renaming}
        onCancel={handleCloseRenaming}
        id={id}
        name={name}
        widgetSlug={widgetSlug}
        description={description}
      />

      <Modal
        title="Delete Widget"
        onOk={handleDeleteWidget}
        open={deleting}
        okText="Delete"
        okButtonProps={{
          danger: true,
          loading: deleteResults?.loading,
        }}
      >
        Are you sure? This widget will be removed from all dashboards of which it has been added.
      </Modal>
    </>
  );
}
