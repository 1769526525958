import { captureException } from '@sentry/browser';
import { Result, Typography } from 'antd';
import { useEffect, type FC } from 'react';
import { useRouteError } from 'react-router';
import { config } from '../config';
import { CurrentUserProvider, useAuthContext } from './auth-context';
import { UnauthenticatedLayout } from './layouts/unauthenticated-layout';
import { Link, NuButton } from './nuspire';

type ErrorPageProps = {
  error: Error;
};

const ErrorPage: FC<ErrorPageProps> = (props) => {
  const { isMyNuspireDev } = useAuthContext();
  const { Paragraph, Text } = Typography;

  return (
    <UnauthenticatedLayout showAuthActions={false}>
      <Result
        status="error"
        title="Oops!"
        subTitle="An unexpected error has occurred."
        extra={[
          <Link to="/" key="goHome">
            <NuButton type="default">Go home</NuButton>
          </Link>,
          <Link to="/logout" key="logout">
            <NuButton type="default">Logout</NuButton>
          </Link>,
        ]}
      >
        <div className="desc">
          <Paragraph>
            <Text strong style={{ fontSize: 16 }}>
              myNuspire Version: {config.myNuspireVersion ?? 'Unknown'}
            </Text>
          </Paragraph>
          <Paragraph>
            <Text strong style={{ fontSize: 16 }}>
              User Agent: {navigator?.userAgent ?? 'Unknown'}
            </Text>
          </Paragraph>
          <Paragraph>
            <Text
              strong
              style={{
                fontSize: 16,
              }}
            >
              Error: {props.error.message}
            </Text>
          </Paragraph>
          {isMyNuspireDev && props.error.stack && (
            <Paragraph>
              <pre>
                <code>{props.error.stack}</code>
              </pre>
            </Paragraph>
          )}
        </div>
      </Result>
    </UnauthenticatedLayout>
  );
};

export const AppErrorBoundary: FC = () => {
  const error = useRouteError() as Error;

  useEffect(() => {
    captureException(error);
  }, [error]);

  return (
    <CurrentUserProvider>
      <ErrorPage error={error} />
    </CurrentUserProvider>
  );
};
