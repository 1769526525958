import { CrownOutlined, ExportOutlined, FileExcelOutlined } from '@ant-design/icons';
import { gql, useLazyQuery } from '@apollo/client';
import { Checkbox, Empty, Input, Table, Tag, Tooltip, message } from 'antd';
import { useAuthContext } from 'components/auth-context';
import { useClientContext } from 'components/client-context-provider';
import { Link, NuButton, Spacer } from 'components/nuspire';
import * as NuIcon from 'components/nuspire/nu-icon';
import { CopyToClipboardIcon, P } from 'components/shared-components';
import UserAuthenticationTypeDisplay from 'components/user-authentication-type-display';
import UserActionButtons from 'components/users/user-management/user-action-buttons';
import UserStatus from 'components/users/user-management/user-status';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from 'react-router';
import styled from 'styled-components';
import { GetAllUsersForAdminQuery, GetAllUsersForAdminQueryVariables } from 'types/graph-codegen/graph-types';
import { useForceRerender } from 'utils/react-hooks/use-force-rerender';
import useSearchParams from 'utils/react-hooks/useSearchParams';
import { config } from '../../../config';
import { Access, IUser, UserAuthenticationType } from '../../../types';
import { BreadcrumbBar } from '../../bread-crumb-bar';
import { Content } from '../../layouts/content';
import { TableScroll } from '../clients/clients';
import { runExportUsersAndDownload } from './export-users';
import { UserImpersonationButton } from './user-impersonation-button';

const ActionRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FirstColumn = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;

  > :not(:last-child) {
    margin-bottom: 0.1rem;
  }
`;

const ActionRowColumn = styled.div``;

const GET_USERS = gql`
  query GetAllUsersForAdmin($search: String, $showDeleted: Boolean, $options: PaginationOptionsInput) {
    allUsers(search: $search, showDeleted: $showDeleted, options: $options) {
      items {
        id
        firstName
        lastName
        email
        login
        lastLogin
        clientId
        serviceNowUserId
        isSuperUser
        phoneNumber
        phoneNumberExt
        authenticationType
        client {
          name
        }
        deletedAt
        status
      }
      nextKey
      count
    }
  }
`;

const exportQueryName = 'allUsers';

const ExternalLink = styled.a`
  color: ${(p) => p.theme.token.colorLink};
  &:hover {
    color: ${(p) => p.theme.token.colorLinkHover};
  }
`;

export type GraphUser = GetAllUsersForAdminQuery['allUsers']['items'][number];

type UsersTableProps = {
  currentPage: number;
  dataSource?: GraphUser[];
  emptyDescription: JSX.Element | string;
  loading: boolean;
  onActionSuccess: () => void;
  onPageChange: (newPage: number) => void;
  pageSize: number;
  searchValue: string;
  total: number;
};

function UsersTable(props: UsersTableProps) {
  const {
    currentPage,
    dataSource,
    emptyDescription,
    loading,
    onActionSuccess,
    onPageChange,
    pageSize,
    searchValue,
    total,
  } = props;
  const { user: currentUser } = useAuthContext();
  const { paginationDefaults } = config;

  return (
    <Table
      loading={loading}
      locale={{
        emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={emptyDescription} />,
      }}
      columns={[
        {
          dataIndex: 'id',
          key: 'view',
          render: (userId: string, user) => {
            const isNotCurrentUser = user.id !== currentUser?.id;
            return (
              <FirstColumn>
                {isNotCurrentUser && <UserImpersonationButton user={user as IUser} style={{ padding: 0 }} />}
                <CopyToClipboardIcon altTooltip="Copy ID to Clipboard" copyText={userId} />
              </FirstColumn>
            );
          },
          width: '35px',
        },
        {
          title: 'User',
          dataIndex: 'id',
          key: 'id',
          render: (_id: string, user) => {
            return (
              <>
                <P style={{ margin: 0 }}>
                  {user?.isSuperUser && (
                    <Tooltip overlay="This user is a superuser">
                      <CrownOutlined style={{ marginRight: '0.5rem' }} />
                    </Tooltip>
                  )}
                  <Link to={{ pathname: `/admin/users/${user.id}` }} state={{ search: searchValue }}>
                    {`${user.firstName} ${user.lastName} <${user.email}>`}
                  </Link>
                </P>
                {user.login && user.login !== user.email && (
                  <P style={{ marginTop: '0.25rem', marginBottom: 0 }}>
                    <strong>Login:</strong> {user.login} <CopyToClipboardIcon copyText={user.login} />
                  </P>
                )}
                {user.deletedAt && <Tag color="red">Deleted</Tag>}
              </>
            );
          },
          width: '300px',
        },
        {
          title: 'Client',
          dataIndex: 'clientId',
          key: 'clientId',
          render: (_id: string, user) => {
            return <Link to={`/admin/clients/${user.clientId}`}>{user?.client?.name}</Link>;
          },
        },
        {
          title: `Status`,
          dataIndex: `status`,
          key: `status`,
          render: (status: string) => <UserStatus status={status} />,
        },
        {
          title: `Last Login`,
          dataIndex: `lastLogin`,
          key: `lastLogin`,
          render: (lastLogin: string) => <p>{lastLogin}</p>,
        },

        {
          title: 'Authentication Type',
          dataIndex: 'authenticationType',
          key: 'authenticationType',
          render: (authenticationType?: UserAuthenticationType) => (
            <UserAuthenticationTypeDisplay authenticationType={authenticationType} />
          ),
        },
        {
          title: 'ServiceNow ID',
          dataIndex: 'serviceNowUserId',
          key: 'serviceNowUserId',
          render: (serviceNowUserId?: string) => {
            return (
              <>
                <ExternalLink
                  href={`${config.serviceNowBaseUrl}/nav_to.do?uri=sys_user.do?sys_id=${serviceNowUserId}%26sysparm_view=case`}
                  target="_blank"
                  rel="noreferrer"
                  style={{ marginRight: serviceNowUserId ? '0.5rem' : undefined }}
                >
                  {serviceNowUserId}
                </ExternalLink>
                {serviceNowUserId && <CopyToClipboardIcon copyText={serviceNowUserId} />}
              </>
            );
          },
        },
        {
          title: `Actions`,
          dataIndex: ``,
          key: `actions`,
          render: (user: IUser) => {
            return <UserActionButtons user={user} onActionSuccess={onActionSuccess} />;
          },
        },
      ]}
      dataSource={dataSource}
      rowKey="id"
      pagination={{
        current: currentPage,
        defaultCurrent: paginationDefaults.currentPage,
        defaultPageSize: paginationDefaults.pageSize,
        pageSize,
        showSizeChanger: false,
        showPrevNextJumpers: true,
        total,
      }}
      onChange={({ current: newCurrentPage }, _filters, _sorterResult, _extra) => {
        if (!newCurrentPage) return;

        onPageChange(newCurrentPage);
      }}
      size="middle"
      bordered
    />
  );
}

export function AllUsers() {
  const { paginationDefaults } = config;
  const { authorize } = useClientContext();
  const navigate = useNavigate();
  const location = useLocation();
  const { parsed } = useSearchParams();
  const search: string = parsed?.search ?? '';
  const [searchValue, setSearchValue] = useState(search);
  const [getAllUsers, { data, error, loading }] = useLazyQuery<
    GetAllUsersForAdminQuery,
    GetAllUsersForAdminQueryVariables
  >(GET_USERS);
  const [currentPage, setCurrentPage] = useState(paginationDefaults.currentPage);
  const [pages, _setPages] = useState(new Map<number, GraphUser[]>());
  const { pageSize } = paginationDefaults;
  const forceRerender = useForceRerender();
  const [showDeletedUsers, setShowDeletedUsers] = useState(false);

  const { authorized: canImportUsers } = authorize({ requiredPermissions: { allUsers: Access.write } });

  useEffect(() => {
    if (error) {
      console.error(error);
      message.error('An error occurred while fetching users.');
    }
  }, [error]);

  useEffect(() => {
    // Don't fire the search if there isn't any search value
    if (!searchValue) return;

    getAllUsers({
      variables: {
        search: searchValue,
        showDeleted: showDeletedUsers,
        options: {
          pageSize,
        },
      },
    });
  }, []);

  useEffect(() => {
    if (!data?.allUsers?.items || pages.has(currentPage)) return;

    const newPageData = data?.allUsers?.items.map((i) => ({ ...i }));
    pages.set(currentPage, newPageData);
    // setPages(new Map<number, GraphUsers[]>(pages.entries()));
    // forceRerender is more performant than above commented-out logic. react dumb sometimes 🤷‍♂️
    // react not detecting changes to map for some reason.
    forceRerender();
  }, [pages, currentPage, data?.allUsers?.items]);

  const resetAndRefetchData = () => {
    pages.clear();
    setCurrentPage(1);
    getAllUsers({
      variables: {
        options: {
          pageSize,
        },
        search: searchValue,
        showDeleted: showDeletedUsers,
      },
    });
  };

  const handleChange = (e: any) => setSearchValue(e.target.value);
  const onSearch = () => {
    resetAndRefetchData();
    navigate({
      pathname: location.pathname,
      search: `?search=${searchValue}`,
    });
  };
  const clearSearch = () => {
    setSearchValue('');
    setShowDeletedUsers(false);
    resetAndRefetchData();
    navigate(location.pathname);
  };

  const breadCrumbBar = (
    <BreadcrumbBar
      items={[
        { text: 'Home', to: '/' },
        { text: 'Admin', to: '/admin' },
        { text: 'Users', to: '/admin/users' },
      ]}
      isLoading={loading}
    />
  );

  const isSearchingUsers = search.trim() !== '';
  const createEmptyDescription = () => {
    if (!isSearchingUsers) {
      return (
        <>
          <div>
            <Input
              type="text"
              placeholder="Search Users"
              onPressEnter={onSearch}
              style={{ width: '300px' }}
              value={searchValue}
              onChange={handleChange}
              autoFocus
            />
          </div>
          <div>
            <NuButton type="primary" icon={<NuIcon.Search />} style={{ marginTop: '1rem' }} onClick={onSearch}>
              Search
            </NuButton>
          </div>
        </>
      );
    }

    if (loading) return 'Loading...';

    return 'No Users found for current search.';
  };

  const EXPORT_USERS_QUERY = gql`
    query ExportAllUsersForAdmin($search: String, $showDeleted: Boolean, $options: PaginationOptionsInput) {
      allUsers(search: $search, showDeleted: $showDeleted, options: $options) {
        items {
          id
          clientId
          createdAt
          login
          email
          firstName
          isSuperUser
          lastName
          lastLogin
          lastLogout
          mobilePhoneNumber
          mobilePhoneNumberCountry
          phoneNumber
          phoneNumberCountry
          phoneNumberExt
          serviceNowUserId
          authenticationType
          identityProviderIds
          managedClientIds
          notificationCount
          jiraCustomerId
        }
        nextKey
        count
      }
    }
  `;

  const [runExport, { loading: exportLoading }] = useLazyQuery(EXPORT_USERS_QUERY);

  return (
    <Content>
      <Helmet title="All Users" />
      {breadCrumbBar}
      <ActionRow>
        <ActionRowColumn>
          {isSearchingUsers && (
            <>
              <Input
                type="text"
                placeholder="Search Users"
                onPressEnter={onSearch}
                style={{ width: '300px' }}
                value={searchValue}
                onChange={handleChange}
                autoFocus
              />
              <Checkbox
                style={{ marginLeft: '0.25rem' }}
                checked={showDeletedUsers}
                onChange={(e) => setShowDeletedUsers(e.target.checked)}
              >
                Show deleted users
              </Checkbox>
              <NuButton type="primary" icon={<NuIcon.Search />} style={{ marginLeft: '18px' }} onClick={onSearch}>
                Search
              </NuButton>
              <NuButton type="default" style={{ marginLeft: '18px' }} onClick={clearSearch}>
                Clear
              </NuButton>
            </>
          )}
        </ActionRowColumn>
        <ActionRowColumn>
          <NuButton
            type="default"
            onClick={() => {
              runExportUsersAndDownload(runExport, searchValue, showDeletedUsers);
            }}
            disabled={loading || exportLoading}
            loading={exportLoading}
            icon={<ExportOutlined />}
          >
            Export
          </NuButton>
          <Link to="/admin/users/import">
            <NuButton
              type="primary"
              style={{ cursor: !canImportUsers ? 'not-allowed' : 'pointer', marginLeft: '6px' }}
              disabled={!canImportUsers}
            >
              <FileExcelOutlined /> Import
            </NuButton>
          </Link>
        </ActionRowColumn>
      </ActionRow>
      <Spacer height="24px" />
      <TableScroll>
        <UsersTable
          currentPage={currentPage}
          dataSource={pages.get(currentPage)}
          emptyDescription={createEmptyDescription()}
          loading={loading}
          pageSize={pageSize}
          searchValue={searchValue}
          total={[...pages.values()].flat().length + 1}
          onActionSuccess={() => {
            resetAndRefetchData();
            forceRerender();
          }}
          onPageChange={(newPage) => {
            setCurrentPage(newPage);

            if (!pages.has(newPage)) {
              // Need to request new data.
              getAllUsers({
                variables: {
                  options: {
                    nextKey: data?.allUsers?.nextKey,
                    pageSize,
                  },
                  search: searchValue,
                  showDeleted: showDeletedUsers,
                },
              });
            }
          }}
        />
      </TableScroll>
    </Content>
  );
}

export default AllUsers;
