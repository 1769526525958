import { type FC } from 'react';
import { Navigate } from 'react-router';
import { REDIRECT_TO_KEY } from '../localstorage';
import { useAuthContext } from './auth-context';
import Spin, { SpinContainer } from './nuspire/spin';

export const DefaultClientRedirect: FC = () => {
  const authContext = useAuthContext();
  const defaultClientId = authContext?.user?.clientId;
  const redirectTo = localStorage.getItem(REDIRECT_TO_KEY);

  if (redirectTo) {
    localStorage.removeItem(REDIRECT_TO_KEY);
    return <Navigate to={redirectTo} replace />;
  }

  if (defaultClientId) {
    return <Navigate to={`/${defaultClientId}/home`} replace />;
  }

  if (authContext.loading) {
    return (
      <SpinContainer
        style={{
          height: '400px',
        }}
      >
        <Spin tip="Loading Client...">
          <div className="content" />
        </Spin>
      </SpinContainer>
    );
  }

  return null;
};
