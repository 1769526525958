import { AreaChartOutlined, BookOutlined, HomeOutlined, SettingOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { MenuDividerType, MenuItemGroupType, MenuItemType, SubMenuType } from 'antd/es/menu/hooks/useItems';
import { settingsPath } from 'components/settings/paths';
import { useOverflowDetector } from 'react-detectable-overflow';
import { NavLink } from 'react-router';
import styled from 'styled-components';
import {
  CaseManagementIcon,
  Chat as ChatIcon,
  NspIcon,
  Services as ServicesIcon,
  ThreatIcon,
} from '../../../nuspire/nu-icon';
import { getAdminMenuItems } from './get-admin-menu-items';

// augmented type based on ItemType from `antd/es/menu/hooks/useItems`
export type MenuItem = LinkedMenuItem | MenuDividerType | MenuItemGroupType | null;
export type LinkedMenuItem = (MenuItemType | SubMenuType) & {
  to?: string;
  children?: MenuItem[];
  'data-intercom-target'?: string; // renders as data-intercom-target
};

export const rootItemKeys: string[] = [
  'home',
  'case-management',
  'reporting-and-analysis',
  'threats',
  'services',
  'risk-and-compliance',
  'knowledge',
  'nutron',
  'settings',
];

// export type RootItemKey = typeof rootItemKeys[number];
export type RootItemKey = string;

export type MenuItemFilters = {
  viewableAdminKeys?: string[];
  showManagedClients?: boolean;
  showEscalations?: boolean;
  showNECES?: boolean;
  showNutron?: boolean;
};

interface RootItemsArgs {
  clientId: string;
  filters?: MenuItemFilters;
  nspDomainItems?: MenuItem[];
  adminItems?: MenuItem[];
  entitlementItems?: MenuItem[];
}

const rootItemsMap = ({
  clientId,
  filters = {},
  nspDomainItems,
  adminItems,
  entitlementItems,
}: RootItemsArgs): Record<RootItemKey, Omit<MenuItem, 'key'>> => ({
  home: {
    label: 'Home',
    to: `/${clientId}/home`,
    icon: <HomeOutlined />,
    'data-intercom-target': 'nav-tour-home',
  },
  'case-management': {
    label: 'Case Management',
    to: `/${clientId}/case-management/overview`,
    icon: <CaseManagementIcon />,
    'data-intercom-target': 'nav-tour-case-management',
    children: [
      {
        key: 'case-mangement|overview',
        label: 'Overview',
        to: `/${clientId}/case-management/overview`,
        'data-intercom-target': 'nav-tour-case-management-overview',
      },
      {
        key: 'case-mangement|cases',
        label: 'Cases',
        to: `/${clientId}/case-management/cases`,
        'data-intercom-target': 'nav-tour-case-management-cases',
      },
      {
        key: 'case-management|incidents',
        label: 'Incidents',
        to: `/${clientId}/case-management/incidents`,
        'data-intercom-target': 'nav-tour-case-management-incidents',
      },
      {
        key: 'case-management|tasks',
        label: 'Tasks',
        to: `/${clientId}/case-management/tasks`,
        'data-intercom-target': 'nav-tour-case-management-tasks',
      },
    ],
  },
  'reporting-and-analysis': {
    label: 'Reporting and Analysis',
    icon: <AreaChartOutlined />,
    'data-intercom-target': 'nav-tour-reporting-and-analysis',
    to: `/${clientId}/reporting-and-analysis`,
    children: [
      // Overview to come
      {
        key: 'overview',
        label: 'Overview',
        to: `/${clientId}/reporting-and-analysis/overview`,
        'data-intercom-target': 'nav-tour-reporting-and-analysis-overview',
      },
      {
        key: 'dashboards',
        label: 'Dashboards',
        to: `/${clientId}/reporting-and-analysis/dashboards`,
        'data-intercom-target': 'nav-tour-reporting-and-analysis-dashboards',
      },
      {
        key: 'Reports',
        label: 'Reports',
        to: `/${clientId}/reporting-and-analysis/reports`,
        'data-intercom-target': 'nav-tour-reporting-and-analysis-reports',
      },
      {
        key: 'data-explorer',
        label: 'Data Explorer',
        to: `/${clientId}/reporting-and-analysis/data-explorer`,
        'data-intercom-target': 'nav-tour-reporting-and-analysis-data-explorer',
      },
    ],
  },
  'risk-and-compliance': {
    label: 'Risk & Compliance',
    to: `/${clientId}/nsp`,
    icon: <NspIcon />,
    'data-intercom-target': 'nav-tour-risk-and-compliance',
    children: [
      {
        key: 'nsp|overview',
        label: 'Overview',
        to: `/${clientId}/nsp/overview`,
      },
      {
        key: 'nsp|roadmap',
        label: 'Roadmap',
        to: `/${clientId}/nsp/roadmap`,
      },
      {
        key: 'nsp|self-assessment',
        label: 'Self Assessment',
        to: `/${clientId}/nsp/self-assessment`,
      },
      ...(filters.showManagedClients
        ? [
            {
              key: 'nsp|managed-clients',
              label: 'Managed Clients',
              to: `/${clientId}/nsp/managed-clients`,
            },
          ]
        : []),
      ...((nspDomainItems?.length ?? 0) > 0
        ? [
            {
              key: 'nsp|domains',
              label: 'Domains',
              children: nspDomainItems,
            },
          ]
        : []),
    ],
  },
  services: {
    label: 'Services',
    to: `/${clientId}/services`,
    icon: <ServicesIcon />,
    'data-intercom-target': 'nav-tour-services',
  },
  nutron: {
    label: 'Nutron',
    to: `/${clientId}/nutron`,
    icon: <ChatIcon />,
  },
  threats: {
    label: 'Threats',
    to: `/${clientId}/threats`,
    icon: <ThreatIcon />,
    'data-intercom-target': 'nav-tour-threats',
    children: [
      {
        key: 'threats|threat-brief',
        label: 'Threat Brief',
        to: `/${clientId}/threats/threat-brief`,
        'data-intercom-target': 'nav-tour-threat-brief',
      },
      {
        key: 'threats|threat-modeling-tool',
        label: 'Threat Modeling Tool',
        to: `/${clientId}/threats/threat-modeling-tool`,
        'data-intercom-target': 'nav-tour-threat-modeling-tool',
      },
    ],
  },
  knowledge: {
    label: 'Knowledge',
    to: `/${clientId}/knowledge`,
    icon: <BookOutlined />,
    'data-intercom-target': 'nav-tour-knowledge',
  },
  settings: {
    label: 'Settings',
    to: settingsPath({ clientId }),
    icon: <SettingOutlined />,
    'data-intercom-target': 'nav-tour-settings',
  },
});

function isDivider(item: MenuItem): item is MenuDividerType {
  return (item as any)?.type === 'divider';
}

export function isLinkedMenuItem(item: MenuItem): item is NonNullable<LinkedMenuItem> {
  return item != null && !isDivider(item) && (item as any).type !== 'group';
}

const OverflowTooltip = styled(Tooltip).attrs({
  mouseEnterDelay: 0,
  placement: 'right',
})`
  text-overflow: ellipsis;
  overflow: hidden;
`;

function MenuItemLabel(props: MenuItem) {
  const { to, label } = props as LinkedMenuItem;
  const { ref, overflow } = useOverflowDetector({});

  if (label == null) return null;

  const innerLabel = to ? (
    <NavLink to={to} style={{ color: 'currentcolor' }}>
      {label}
    </NavLink>
  ) : (
    label
  );

  return overflow ? (
    <OverflowTooltip title={label}>
      <div ref={ref as any}>{innerLabel}</div>
    </OverflowTooltip>
  ) : (
    <div ref={ref as any}>{innerLabel}</div>
  );
}

function transformLinkedMenuItem(item: MenuItem): MenuItem {
  const { to: _to, label, ...rest } = item as LinkedMenuItem;
  const children = (item as MenuItemGroupType).children?.map(transformLinkedMenuItem);

  if (!item || isDivider(item) || !label) return item;

  // label isn't a plain ol' string? let's not touch it
  const isCustomLabel = typeof label !== 'string';

  const { key, ...restProps } = item;
  return {
    ...rest,
    children,
    label: isCustomLabel ? label : <MenuItemLabel key={key!} {...restProps} />,
  };
}

export interface GetMainMenuItemsArgs {
  clientId: string;
  filters?: MenuItemFilters;
  menuOrder?: RootItemKey[];
  nspDomainItems?: MenuItem[];
  entitlementItems?: MenuItem[];
}

export function getRawMainMenuItems(args: GetMainMenuItemsArgs): MenuItem[] {
  const { filters, entitlementItems } = args;
  const { viewableAdminKeys = [], showEscalations, showNECES, showNutron } = filters ?? {};

  const rootKeys = rootItemKeys;

  // Need to filter here to make sure we filter out old menu keys that are no longer used in new nav
  const menuOrder = [...new Set([...(args.menuOrder ?? []), ...rootKeys])].filter((key) => rootKeys.includes(key));
  const adminItems = getAdminMenuItems({ viewableAdminKeys });

  const items = rootItemsMap({
    ...args,
    adminItems,
  });

  return menuOrder.flatMap((key) => {
    if (key === 'admin' && (viewableAdminKeys ?? []).length === 0) return [];
    if (key === 'escalations' && !showEscalations) return [];
    if (key === 'entitlements' && (entitlementItems ?? []).length === 0) return [];
    if (key === 'neces' && !showNECES) return [];
    if (key === 'nutron' && !showNutron) return [];

    return {
      key,
      ...items[key],
    };
  });
}

type MainMenuItems = {
  // items to be rendered before dashboard menu
  preDashboardItems: MenuItem[];
  // items to be rendered after dashboard menu
  postDashboardItems: MenuItem[];
};

export function getMainMenuItems(args: GetMainMenuItemsArgs): MainMenuItems {
  const items = getRawMainMenuItems(args);

  // dashboards nav item is a bit unique and requires special attention.
  // here we group items as to whether they are before or after the dashboards nav item.
  const dashboardsKeyIndex = items.findIndex((item) => item?.key === 'dashboards');

  return items.reduce<MainMenuItems>(
    (acc, item, i) => {
      if (i === dashboardsKeyIndex) return acc;

      const transformedItem = transformLinkedMenuItem(item);

      if (i < dashboardsKeyIndex) {
        acc.preDashboardItems.push(transformedItem);
      }

      if (i > dashboardsKeyIndex) {
        acc.postDashboardItems.push(transformedItem);
      }
      return acc;
    },
    {
      preDashboardItems: [],
      postDashboardItems: [],
    },
  );
}
