import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { App, Layout, Modal, Tabs, Typography, message, type TabsProps } from 'antd';
import { useAuthContext } from 'components/auth-context';
import { Features, useFeatureFlag } from 'components/feature-flags';
import { NuButton } from 'components/nuspire';
import { UPDATE_PROFILE_MUTATION, UpdateUserProfileMutation } from 'components/user-profile/user-profile-form';
import { SYNC_USER_PROFILE_MUTATION } from 'components/users/user-management/sync-user-profile-button';
import { config } from 'config';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router';
import { useIntercom } from 'react-use-intercom';
import styled from 'styled-components';
import {
  ImpersonateUserMutation,
  ImpersonateUserMutationVariables,
  SyncUserProfileMutation,
} from 'types/graph-codegen/graph-types';
import { isRouteConnectorRedirect } from 'utils/is-route-connector-redirect';
import {
  clearImpersonatedAccessToken,
  getImpersonatedAccessToken,
  getTokenExpiration,
  isImpersonationActive,
  isTokenExpired,
  parseJwt,
  setImpersonatedAccessToken,
} from '../../../utils/access-tokens';
import { isRouteDashboardReport } from '../../../utils/is-route-dashboard-report';
import {
  IMPERSONATE_USER_MUTATION,
  USER_IMPERSONATION_STARTED_EVENT,
  USER_IMPERSONATION_STOPPED_EVENT,
  dispatchUserImpersonatedEvent,
  stopUserImpersonation,
} from '../../admin/users/user-impersonation-button';
import { useClientContext } from '../../client-context-provider';
import { P } from '../../shared-components';
import { DashboardMenuContextProvider } from './navigation/dashboard-menu-context';
import { MainMenu } from './navigation/main-menu';
import { TopNav } from './top-nav';

const { Header } = Layout;

const Content = styled(Layout.Content)<{ $navCollapsed: boolean }>`
  margin-left: ${(p) => !p.$navCollapsed && '240px'};
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition-delay: ${(p) => p.$navCollapsed && '0.1s'};
`;

export function AuthenticatedLayout(props: { children: any }) {
  const { children } = props;

  const location = useLocation();
  const { user, refresh } = useAuthContext();
  const clientContext = useClientContext();
  const innerLayout = useRef<HTMLElement>(null);
  const { boot, shutdown } = useIntercom();
  const { modal } = App.useApp();

  const [navCollapsed, setNavCollapsed] = useState(false);
  const [showEULAModal, setShowEULAModal] = useState<boolean>(!user?.acceptedLicenseAgreement);
  const isNutronEnabled = useFeatureFlag(Features.Nutron);
  const [useMinimalLayout, setUseMinimalLayout] = useState<boolean>(true);
  const [impersonationWarningModal, setImpersonationWarningModal] = useState<ReturnType<typeof modal.confirm>>();

  const [syncUserProfile] = useMutation<SyncUserProfileMutation>(SYNC_USER_PROFILE_MUTATION);
  const [impersonateUser] = useMutation<ImpersonateUserMutation, ImpersonateUserMutationVariables>(
    IMPERSONATE_USER_MUTATION,
  );

  let impersonationWarningTimer: number | null = null;
  let impersonationExpirationTimer: number | null = null;

  const handleToggleMainMenu = () => setNavCollapsed((prev) => !prev);

  useEffect(() => {
    setUseMinimalLayout(isRouteDashboardReport(location.pathname) || isRouteConnectorRedirect(location.pathname));
  }, [location]);

  useEffect(() => {
    if (isImpersonationActive()) {
      startImpersonationTimer();
    }

    // Listen for when we impersonate a user.
    document.addEventListener(USER_IMPERSONATION_STARTED_EVENT, startImpersonationTimer);
    document.addEventListener(USER_IMPERSONATION_STOPPED_EVENT, stopImpersonationTimer);

    // Cleanup on unmount.
    return () => {
      clearTimers();
      document.removeEventListener(USER_IMPERSONATION_STARTED_EVENT, startImpersonationTimer);
      document.removeEventListener(USER_IMPERSONATION_STOPPED_EVENT, stopImpersonationTimer);
    };
  }, []);

  const refreshImpersonation = async (): Promise<void> => {
    const impersonatedAccessToken = getImpersonatedAccessToken();
    if (!impersonatedAccessToken) {
      return;
    }
    const parsed = parseJwt(impersonatedAccessToken);
    if (!('userId' in parsed)) {
      console.warn('No userId found in impersonation token. Cannot refresh.');
      return;
    }
    const { userId: impersonatingUserId } = parsed;

    // 1. Clear the current impersonation token.
    clearImpersonatedAccessToken();
    // 2. Re-impersonate.
    const { data, errors } = await impersonateUser({ variables: { userId: impersonatingUserId } });
    if (errors?.length || !data?.impersonateUser?.accessToken) {
      console.error(errors);
      message.error('An error occurred while attempting to refresh your impersonation session');
      return;
    }

    const newToken = data.impersonateUser.accessToken;
    // 3. Store new impersonation token.
    setImpersonatedAccessToken(newToken);
    // 4. Re-emit the impersonation event.
    dispatchUserImpersonatedEvent(data.impersonateUser.user.id);

    message.success('Your impersonation session has been successfully refreshed!');
  };

  const stopImpersonation = async () => {
    impersonationWarningModal?.destroy();

    setImpersonationWarningModal(undefined);
    stopUserImpersonation(() => refresh({ redirectToFn: (cId) => `/${cId}/home` }));
  };

  const notifyImpersonationWillExpire = (): void => {
    const impersonatedToken = getImpersonatedAccessToken();
    if (impersonatedToken === null) {
      return;
    }

    const tokenExpiration = getTokenExpiration(impersonatedToken);
    if (!tokenExpiration) {
      return;
    }

    setImpersonationWarningModal(
      modal.confirm({
        maskClosable: false,
        title: 'Your impersonation session will expire soon!',
        content: <P>Your impersonation session will be stopped soon. Do you want to refresh your session?</P>,
        icon: <ExclamationCircleOutlined />,
        okText: 'Keep impersonating',
        cancelText: 'Stop impersonating',
        onOk: refreshImpersonation,
        onCancel: stopImpersonation,
        width: 750,
      }),
    );
  };

  const clearTimers = (): void => {
    // Clear the current timer, if its set
    if (impersonationWarningTimer !== null) {
      clearTimeout(impersonationWarningTimer);
    }
    if (impersonationExpirationTimer !== null) {
      clearTimeout(impersonationExpirationTimer);
    }
  };

  const startImpersonationTimer = (): void => {
    const impersonatedToken = getImpersonatedAccessToken();
    if (impersonatedToken === null) {
      return;
    }

    // If the token is already expired, stop their impersonation session.
    const isExpired = isTokenExpired(impersonatedToken);
    if (isExpired) {
      stopImpersonation();
      return;
    }

    const tokenExpiration = getTokenExpiration(impersonatedToken);
    if (!tokenExpiration) {
      return;
    }

    const diff = tokenExpiration.getTime() - Date.now();
    const offset = 2 * 60 * 1_000; // 2 minutes in milliseconds
    // We want to alert the user _before_ the token expires so they have a chance to renew.
    const notificationMs = diff - offset;

    clearTimers();

    impersonationWarningTimer = window.setTimeout(notifyImpersonationWillExpire, notificationMs);
    impersonationExpirationTimer = window.setTimeout(stopImpersonation, diff);
  };

  const stopImpersonationTimer = (): void => {
    clearTimers();
  };

  useEffect(() => {
    if (!user) return;

    const options = {
      name: `${user.firstName} ${user.lastName}`,
      email: user.email,
      userId: user.id,
      company: {
        companyId: user.client?.id ?? 'Unknown',
        name: user.client?.name ?? 'Unknown',
        industry: user.client?.industry?.name ?? 'Unknown',
        hasServices: !!clientContext?.client?.serviceOfferings?.length,
      },
      customAttributes: {
        hasLoggedIn: true,
      },
    };

    shutdown();
    boot(options); // start intercom with user data

    syncUserProfile();
  }, [user]);

  const clientId = clientContext.clientId ?? user?.clientId;

  const titleTemplate = `${config.titleEnv} myNuspire Portal | %s`.trimStart();

  return (
    <>
      <Helmet titleTemplate={titleTemplate} title="myNuspire Portal" />
      <Layout>
        {!useMinimalLayout && (
          <Header
            style={{
              position: 'fixed',
              zIndex: 1000,
              width: '100%',
            }}
          >
            <TopNav onOpenNav={handleToggleMainMenu} navCollapsed={navCollapsed} />
          </Header>
        )}
        <Layout
          ref={innerLayout}
          style={{
            paddingTop: !useMinimalLayout ? '64px' : undefined,
          }}
        >
          {useMinimalLayout && children}
          {!useMinimalLayout && (
            <DashboardMenuContextProvider clientId={clientId ?? ''}>
              <Layout.Sider
                collapsed={navCollapsed}
                collapsedWidth={0}
                theme="light"
                trigger={null}
                width={240}
                style={{
                  overflow: 'auto',
                  height: 'calc(100vh - 64px)',
                  position: 'fixed',
                  left: 0,
                  top: '64px',
                  bottom: 0,
                }}
              >
                <MainMenu />
              </Layout.Sider>
              <Content $navCollapsed={navCollapsed}>{children}</Content>
            </DashboardMenuContextProvider>
          )}
        </Layout>
        {isNutronEnabled && <EULAAcceptanceModal open={showEULAModal} setShowEULAModal={setShowEULAModal} />}
      </Layout>
    </>
  );
}

const EULADiv = styled.div`
  max-height: 500px;
  overflow-y: scroll;
`;

const EULATitle = styled(Typography.Title).attrs({ level: 5 })``;

const EULAAcceptanceModal = (args: { open: boolean; setShowEULAModal: Function }) => {
  const { open, setShowEULAModal } = args;
  const [updateUserProfile, { loading }] = useMutation<UpdateUserProfileMutation>(UPDATE_PROFILE_MUTATION);

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'End-User License Agreement',
      children: (
        <EULADiv>
          <Typography.Text>
            Nutron is an AI-powered cybersecurity assistant created by Nuspire. This End-User Agreement ("Agreement")
            governs your use of the Assistant. Please read it carefully.
          </Typography.Text>
          <EULATitle>What is Nutron?</EULATitle>
          <Typography.Text>
            Nutron is trained on data from our company's cybersecurity knowledge sources, including our company and
            service knowledge bases, vendor documentation, threat intelligence feeds, and the MITRE ATT&CK framework.
            Its purpose is to provide general cybersecurity information and assistance to users.
          </Typography.Text>
          <EULATitle>What Nutron is Not</EULATitle>
          <Typography.Text>
            While Nutron draws from authoritative sources, it is not a substitute for professional cybersecurity advice
            or services. You should not rely solely on Nutron when making critical security decisions or taking security
            actions. We do not guarantee the accuracy or completeness of the information provided.
          </Typography.Text>
          <EULATitle>Your Responsibilities</EULATitle>
          <Typography.Text>
            You agree to use Nutron only for lawful purposes and in accordance with this Agreement. Do not attempt to
            reverse engineer, modify, or create derivative works based on Nutron. Any information provided to Nutron is
            considered the property of Nuspire and its affiliates unless otherwise stated. It’s the responsibility of
            the User to ensure the data submitted is theirs to submit. It is also the responsibility of the User to
            validate information provided for accuracy and completeness. Should questions arise, visit{' '}
            <a href="https://www.nuspire.com/contact-us/">contact us</a>. Content provided by Nutron, a service provided
            by Nuspire, does not constitute an endorsement by Nuspire, its affiliates, or its partners of such content.
          </Typography.Text>
          <EULATitle>Data Practices</EULATitle>
          <Typography.Text>
            In the course of your interactions, Nutron may collect certain data, such as your queries and device
            information. We handle any such data consistent with our Privacy Policy and applicable laws. Nuspire’s
            privacy statement can be viewed here: <a href="https://www.nuspire.com/privacy-policy/">privacy policy</a>.
          </Typography.Text>
          <EULATitle>Termination</EULATitle>
          <Typography.Text>
            We reserve the right to suspend or terminate your access to Nutron at any time, for any reason, without
            notice.
          </Typography.Text>
          <EULATitle>Governing Law</EULATitle>
          <Typography.Text>
            This Agreement shall be governed by the laws of the United States of America.
          </Typography.Text>
          <EULATitle>Limitations and Changes</EULATitle>
          <Typography.Text>
            We may update this Agreement from time to time. If a provision is unenforceable, the remaining terms still
            apply. This is the entire agreement regarding Nutron and supersedes any prior understandings.
          </Typography.Text>
          <Typography.Text>
            By using Nutron, you accept the terms of this Agreement. We're excited for you to explore its capabilities
            while adhering to these comittments.
          </Typography.Text>
          <Typography.Text>Last Updated: July 17th, 2024 </Typography.Text>
        </EULADiv>
      ),
    },
    {
      key: '2',
      label: 'AI Transparency and Data Usage',
      children: (
        <EULADiv>
          <EULATitle>How We Collect Data:</EULATitle>
          <ul>
            <li>
              All inputs to Nutron are recorded. Humans review these inputs for the purpose of maintaining our model's
              accuracy, ensuring relevance, and preventing the perpetuation of harmful biases.
            </li>
          </ul>
          <EULATitle>How We Store Data:</EULATitle>
          <ul>
            <li>
              Nutron inputs are securely stored within Nuspire's proprietary environment, leveraging both trusted
              third-party cloud providers and on-premise solutions. Importantly, this data is never shared with the
              creators of the large language models (LLMs) Nutron utilizes, ensuring confidentiality. Additionally, all
              data is encrypted both in transit and at rest to safeguard against unauthorized access.
            </li>
          </ul>
          <EULATitle>Nutron Access:</EULATitle>
          <ul>
            <li>
              Access to Nutron is managed through myNuspire permissions, specifically allowing users in the “Nutron
              User” group to access the tool. Furthermore, access restrictions can be implemented at both the
              organizational and individual user levels as needed.
            </li>
          </ul>
          <EULATitle>User Feedback:</EULATitle>
          <ul>
            <li>Humans at Nuspire review all user feedback and incorporate it to improve Nutron where applicable.</li>
          </ul>
          <EULATitle>Handling of Personally Identifiable Information (PII):</EULATitle>
          <ul>
            <li>
              Nutron processes documentation, EDR, SIEM, and other data sources which could possibly contain PII. In
              instances where PII is encountered, Nutron employs automated anonymization techniques to protect sensitive
              information.
            </li>
          </ul>
          <EULATitle>Model Training:</EULATitle>
          <ul>
            <li>
              The product development team is responsible for improvements to Nutron's responses. It is crucial to note
              that Nutron does not adapt or learn from end-user inputs directly.
            </li>
          </ul>
          <EULATitle>Data Sources Referenced by Nutron:</EULATitle>
          <ul>
            <li>
              Nutron enriches its operations with data from the following sources:
              <ul>
                <li>ServiceNow Knowledge Base documentation</li>
                <li>SentinelOne, including Threats and Alerts, and Agent data</li>
                <li>Selected FortiSIEM data</li>
                <li>3rd Party Threat Intelligence (MITRE, Threat Quotient, etc)</li>
              </ul>
            </li>
            <li>
              Importantly, Nutron's intelligence is derived from its foundational understanding of language provided by
              its LLMs, without pulling knowledge learned from the internet during their training phases. The only
              training information Nutron can access is general cybersecurity information.
            </li>
          </ul>
          <EULATitle>Compliance:</EULATitle>
          <ul>
            <li>
              Nuspire is actively working towards achieving ISO-42001 certification, underscoring our commitment to the
              responsible development and application of AI technologies.
            </li>
          </ul>
        </EULADiv>
      ),
    },
  ];

  return (
    <Modal
      open={open}
      closable={false}
      footer={
        <div>
          <NuButton
            type="primary"
            loading={loading}
            onClick={async () => {
              const response = await updateUserProfile({ variables: { input: { acceptedLicenseAgreement: true } } });

              if (response.errors) {
                message.error(`An error occured while attempting to update your user.`);
                console.log(response.errors);
              } else {
                setShowEULAModal(false);
              }
            }}
          >
            Accept & Agree
          </NuButton>
        </div>
      }
      title="Nutron License Agreement"
      style={{ minWidth: '50%' }}
    >
      <Tabs items={items} />
    </Modal>
  );
};
