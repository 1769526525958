import { AreaChartOutlined, DownOutlined } from '@ant-design/icons';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Form as AntForm, App, Button, Divider, Dropdown, Form, Input, MenuProps, Modal, message } from 'antd';
import { useClientContext } from 'components/client-context-provider';
import ActionFormField from 'components/cyberx/actions/action-form/action-form-field';
import { ActionFormFieldDef } from 'components/cyberx/actions/types';
import { formFieldsToOperatorYupSchema } from 'components/cyberx/actions/utils';
import { IWidget } from 'components/dashboard/dashboard-details';
import { NuButton, NuCard, NuCardContent } from 'components/nuspire';
import EmptyState from 'components/nuspire/nu-empty-state';
import PageHeader from 'components/nuspire/nu-page-header';
import Spin, { SpinContainer } from 'components/nuspire/spin';
import ClientTreeSelect from 'components/organization/client-tree-select';
import { dashboardDetailPath, widgetDetailPath } from 'components/reporting-and-analysis/paths';
import { Formik, FormikContextType, useFormikContext } from 'formik';
import debounce from 'lodash.debounce';
import { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router';
import styled from 'styled-components';
import { DashboardWidgetsByWidgetIdQuery } from 'types/graph-codegen/graph-types';
import { client } from 'utils/graphql';
import useSearchParams from 'utils/react-hooks/useSearchParams';
import { Content } from '../../layouts/content';
import Widget from '../widget';
import { CURRENT_DASHBOARD_ID_SP } from '../widget/widget';
import AddToDashboardsButton from './add-to-dashboards-button';
import DuplicateWidgetButton from './duplicate-widget-button';
import { WidgetSettingsButton } from './widget-settings-button';

const WIDGET_DEFINITION_DATA = gql`
  query WidgetDefinitionData(
    $clientId: String!
    $slug: String!
    $settings: JSONObject
    $variables: JSONObject
    $viewingClientId: String
    $filters: [QueryDataTypeFilter!]
  ) {
    widgetDefinition(slug: $slug) {
      id
      slug
      type
      data(
        clientId: $clientId
        settings: $settings
        variables: $variables
        viewingClientId: $viewingClientId
        filters: $filters
      )
      configuration(settings: $settings)
      name
      description
      dataType
    }
  }
`;

const WIDGET_DEFINITION = gql`
  query WidgetDefinition($slug: String!) {
    widgetDefinition(slug: $slug) {
      id
      slug
      name
      description
      dataType
      fields {
        key
        label
        type
        jsonSchema
        required
        editable
        inputType
        parameters
        inputType
        hasGetOptions
        filterInputOperators
        defaultString
      }
    }
  }
`;

const WIDGET_SEARCH = gql`
  query WidgetEditorWidgetSearch($clientId: String!, $queryString: String, $widgetSlug: String) {
    widgetSearch(clientId: $clientId, queryString: $queryString, size: 100, widgetSlug: $widgetSlug) {
      items {
        id
        name
        widgetSlug
        description
        # server side widget definition.
        widgetDefinition {
          id
          name
          description
        }
        dashboardWidgets {
          id
          dashboard {
            id
            name
          }
        }
        client {
          id
          name
        }
      }
      next
      total
    }
  }
`;

export interface IWidgetDefinition {
  id: string;
  slug: string;
  name: string;
  description?: string;
  fields?: ActionFormFieldDef[];
  dataType?: string;
}
type WidgetDefinitionQuery = {
  widgetDefinition: IWidgetDefinition;
};

export function ChangeHandler(props: {
  onChange?: (settings: any) => void;
  handleFormikContext: (ctx: FormikContextType<any>) => void;
}) {
  const { onChange, handleFormikContext } = props;

  // formik context
  const formikContext = useFormikContext();
  const { values } = formikContext;
  const stringified = JSON.stringify(values);

  // call on change after variables change.
  const debouncedOnChange = useCallback(
    debounce(async (ctx: FormikContextType<any>) => {
      const { values: val } = ctx;

      if (onChange) {
        onChange(val);
      }

      handleFormikContext(ctx);
    }, 500),
    [],
  );

  useEffect(() => {
    debouncedOnChange(formikContext);
  }, [stringified]);

  return null;
}

const WefRoot = styled.div`
  margin-bottom: 90px;
`;
function WidgetEditorForm(props: {
  widgetDefinition: IWidgetDefinition;
  initialValues?: any;
  handleChange: (settings: any) => void;
  handleFormikContext: (ctx: FormikContextType<any>) => void;
}) {
  const { clientId } = useClientContext();
  const {
    widgetDefinition: { fields, dataType },
    initialValues,
    handleChange,
    handleFormikContext,
  } = props;

  if (!fields || fields?.length === 0) {
    // no need for form.
    return null;
  }

  const yupSchema = formFieldsToOperatorYupSchema(fields);

  return (
    <WefRoot>
      <PageHeader title="Options" level={4} />
      <Formik
        initialValues={initialValues ?? {}}
        onSubmit={async (_values, _helpers) => {
          console.warn('Nothing to do in onSubmit');
        }}
        // client identifiers is going to break the yup schema.
        validationSchema={yupSchema}
      >
        {(formik) => {
          return (
            <AntForm layout="vertical">
              <ChangeHandler handleFormikContext={handleFormikContext} onChange={handleChange} />
              {fields.map((f) => (
                <ActionFormField
                  key={f.key}
                  field={f}
                  formik={formik}
                  enableClientIdentifiers
                  dataTypeKey={dataType}
                  supportOperators
                  clientId={clientId}
                />
              ))}
            </AntForm>
          );
        }}
      </Formik>
    </WefRoot>
  );
}

function checkIsUsingClientIdentifiers(args: { settings: any }) {
  const { settings } = args;

  if (!settings || typeof settings !== 'object') {
    return false;
  }

  const settingWithClientIdentifier = Object.keys(settings).find((settingKey) => {
    if (settings[settingKey]?.['$client-identifier'] !== undefined) {
      return true;
    }

    return false;
  });

  return Boolean(settingWithClientIdentifier);
}

export function WidgetPreview(props: {
  slug: string;
  settings?: any; // should come from form.
  filters?: any;
  showHeader?: boolean;
}) {
  const { clientId } = useClientContext();
  const { slug, settings, filters, showHeader = true } = props;

  const isUsingClientIdentifiers = checkIsUsingClientIdentifiers({ settings });

  const [viewingClientId, setviewingClientId] = useState<string | undefined>();
  const handleviewingClientId = (val?: string | null) => {
    setviewingClientId(val ?? undefined);
  };

  const { data, loading } = useQuery(WIDGET_DEFINITION_DATA, {
    variables: {
      clientId,
      settings,
      slug,
      viewingClientId,
      filters,
    },
  });
  const widgetDefinition = data?.widgetDefinition;

  const handleFetch = async (args: { variables?: any }) => {
    const fetchResults = await client.query({
      query: WIDGET_DEFINITION_DATA,
      variables: {
        clientId,
        settings,
        slug,
        viewingClientId,
        variables: args.variables,
        filters,
      },
      fetchPolicy: 'network-only',
    });

    const data = fetchResults?.data?.widgetDefinition?.data;

    return data;
  };

  if (widgetDefinition) {
    return (
      <>
        {showHeader ? <PageHeader level={5} title="Widget Preview" /> : null}

        {isUsingClientIdentifiers && (
          <AntForm layout="vertical">
            <AntForm.Item label="Client Identifier Client Preview">
              <ClientTreeSelect value={viewingClientId ?? null} onChange={handleviewingClientId} />
            </AntForm.Item>
          </AntForm>
        )}

        <div style={{ height: '500px' }}>
          <Widget widgetDefinition={widgetDefinition} handleFetch={handleFetch} />
        </div>
      </>
    );
  }

  if (loading) {
    return (
      <NuCard fullHeight>
        <NuCardContent>
          <SpinContainer>
            <Spin />
          </SpinContainer>
        </NuCardContent>
      </NuCard>
    );
  }

  return null;
}

export const CREATE_WIDGET = gql`
  mutation CreateWidget($input: CreateWidgetInput!) {
    createWidget(input: $input) {
      id
    }
  }
`;

const UPDATE_WIDGET = gql`
  mutation UpdateWidget($id: String!, $input: UpdateWidgetInput!) {
    updateWidget(id: $id, input: $input) {
      id
      settings
    }
  }
`;

type CreateWidgetModalInput = {
  clientId: string;
  widgetSlug: string;
  name: string;
  description?: string;
  settings: any;
  filters: any;
};

function CreateWidgetModal(props: { widgetSlug?: string; input: CreateWidgetModalInput | null; onCancel: () => void }) {
  const { input, widgetSlug } = props;

  // hooks
  const { parsed: searchParams } = useSearchParams();
  const navigate = useNavigate();
  const [createWidget] = useMutation(CREATE_WIDGET);
  const [addWidgetToDashboard] = useMutation(ADD_WIDGET_TO_DASHBOARDS);
  const dashboardId = searchParams[CURRENT_DASHBOARD_ID_SP];
  const { modal } = App.useApp();

  if (!input) {
    return null;
  }

  const { clientId } = input;

  const handleFinish = async (values: any) => {
    const { name, description } = values;

    const { data: getDashboardData } = await client.query({
      query: DASHBOARD_NAME,
      variables: { id: dashboardId },
      fetchPolicy: 'network-only',
    });

    const { data } = await client.query({
      query: WIDGET_SEARCH,
      variables: { clientId, queryString: name, widgetSlug },
      fetchPolicy: 'network-only',
    });

    const variables = {
      input: {
        ...input,
        name,
        description,
      },
    };

    const create = async () => {
      // Create the widget
      const createResult = await createWidget({ variables });
      const newWidgetId = createResult?.data?.createWidget?.id;

      const dashboards: string[] = [];

      if (dashboardId) {
        dashboards.push(dashboardId);
      }

      const widgetId = createResult?.data?.createWidget?.id;

      if (dashboards.length) {
        const dashboardWidgetVariables = {
          widgetId,
          dashboardIds: dashboards,
        };

        const dashboardName = getDashboardData?.dashboard?.name;
        const result = await addWidgetToDashboard({ variables: dashboardWidgetVariables });
        const ok = result?.data?.addWidgetToDashboards?.ok ?? false;

        if (ok) {
          message.success(`Widget has been added to ${dashboardName} `);
        }
      }

      if (newWidgetId) {
        message.success(`Successfully created widgets.`);

        navigate(widgetDetailPath({ clientId, id: newWidgetId, dashboardId }));
      }
    };

    const matchingName = data?.widgetSearch?.items[0]?.name;

    if (matchingName === name) {
      return modal.confirm({
        onOk: create,
        title: 'A widget with the same name already exists',
        content: `Would you like to add this widget anyway "${name}"`,
      });
    }

    return create();
  };

  return (
    <Modal title="Create Widget" footer={false} onCancel={props.onCancel} open>
      <Form
        initialValues={{ name: input.name, description: input.description }}
        onFinish={handleFinish}
        layout="vertical"
      >
        <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Widget name is required.' }]}>
          <Input />
        </Form.Item>
        <Form.Item name="description" label="Description">
          <Input.TextArea />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Create
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

const ADD_WIDGET_TO_DASHBOARDS = gql`
  mutation AddWidgetToDashboards($widgetId: String!, $dashboardIds: [String]) {
    addWidgetToDashboards(widgetId: $widgetId, dashboardIds: $dashboardIds) {
      ok
    }
  }
`;

const DASHBOARD_NAME = gql`
  query DashboardName($id: String!) {
    dashboard(id: $id) {
      id
      name
    }
  }
`;
function SaveWidgetButton(props: {
  widgetDefinition: IWidgetDefinition;
  widget?: IWidget;
  widgetSlug?: string;
  formikContext?: FormikContextType<any>;
}) {
  const { clientId } = useClientContext();
  const { widgetDefinition, formikContext, widget, widgetSlug } = props;

  const [updateWidget] = useMutation(UPDATE_WIDGET);
  const { isSubmitting = false, errors, dirty = false } = formikContext ?? {};

  const hasErrors = errors ? Boolean(Object.keys(errors).length) : false;

  const canSubmit = (!widget || dirty) && !isSubmitting && !hasErrors;

  const [createWidgetInput, setCreateWidgetInput] = useState<CreateWidgetModalInput | null>(null);

  const handleSave = async () => {
    // stringify formik values
    const filters = parseFormikValuesToFilters(formikContext?.values ?? {});

    // deprecated style of settings. Eventually settings will be deprecated in favor of filters;
    const settings = filters.reduce((acc: { [key: string]: any }, filter) => {
      acc[filter.key] = filter.value;

      return acc;
    }, {});

    if (widget) {
      // update widget
      const input = {
        settings,
        filters,
      };

      await updateWidget({
        variables: {
          id: widget.id,
          input,
        },
      });

      message.success('Changes have successfully been saved.');
    } else {
      // create widget
      if (!clientId) {
        console.warn('clientId not present');
        return;
      }
      const input: CreateWidgetModalInput = {
        clientId,
        name: widgetDefinition.name,
        description: widgetDefinition.description,
        widgetSlug: widgetDefinition.slug,
        settings,
        filters,
      };

      // this should open the modal
      setCreateWidgetInput(input);
    }
  };

  return (
    <>
      <NuButton size="large" onClick={handleSave} disabled={!canSubmit} type="primary">
        Save
      </NuButton>
      <CreateWidgetModal
        input={createWidgetInput}
        onCancel={() => setCreateWidgetInput(null)}
        widgetSlug={widgetSlug}
      />
    </>
  );
}

const DASHBOARD_WIDGET_BY_WIDGET_ID = gql`
  query DashboardWidgetsByWidgetId($widgetId: String!) {
    dashboardWidgetsByWidgetId(widgetId: $widgetId) {
      id
      dashboardId
      widgetId
      dashboard {
        id
        name
      }
    }
  }
`;

function DashboardDropDown(props: { widgetId: string; widgetSlug?: string }) {
  const { widgetId } = props;

  const { clientId = '' } = useClientContext();

  //get all dashboards on widget, map to menu props
  const { data, loading } = useQuery<DashboardWidgetsByWidgetIdQuery>(DASHBOARD_WIDGET_BY_WIDGET_ID, {
    variables: { widgetId },
  });

  const items: MenuProps['items'] =
    data?.dashboardWidgetsByWidgetId?.map((item) => ({
      key: item?.id ?? 'no-dashboards-found',
      label: (
        <Link to={dashboardDetailPath({ clientId, id: item?.dashboardId ?? '' })}>
          <Button type="text" style={{ textAlign: 'left' }}>
            {item?.dashboard.name}
          </Button>
        </Link>
      ),
    })) ?? [];

  items.push({
    key: 'add-to-more-dashboards-btn',
    label: (
      <div style={{ textAlign: 'center', marginTop: '-20px' }}>
        <Divider />
        <AddToDashboardsButton widgetId={widgetId} size="small" type="default" buttonContent="Add to More Dashboards" />
      </div>
    ),
  });

  return (
    <Dropdown menu={{ items }} trigger={['click']}>
      <NuButton icon={<DownOutlined />} size="small" type="text" style={{ marginRight: '3px', fontWeight: 'bold' }} />
    </Dropdown>
  );
}

function BackToDashboardButton(props: { dashboardId: string }) {
  const { dashboardId } = props;
  const { clientId } = useClientContext();
  //Get current dashboardId
  const { data } = useQuery(DASHBOARD_NAME, {
    variables: { id: dashboardId },
    skip: !dashboardId,
  });
  const dashboardName = data?.dashboard?.name;

  const navigate = useNavigate();

  const returnToDash = () => {
    navigate(dashboardDetailPath({ clientId: clientId ?? '', id: dashboardId }));
  };

  return (
    <Button
      type="text"
      style={{ fontWeight: 'bold', paddingRight: '5px' }}
      onClick={returnToDash}
      icon={<AreaChartOutlined />}
      size="large"
    >
      {dashboardName}
    </Button>
  );
}

function WidgetEditorPageHeader(props: {
  widgetDefinition: IWidgetDefinition;
  widget?: IWidget;
  widgetSlug?: string;
  formikContext?: FormikContextType<any>;
}) {
  const { widgetDefinition, widget } = props;

  const { parsed: searchParams } = useSearchParams();

  //Get current dashboardId
  const dashboardId = searchParams[CURRENT_DASHBOARD_ID_SP];

  return (
    <PageHeader
      title={
        <div style={{ paddingBottom: '5px' }}>
          {dashboardId && <BackToDashboardButton dashboardId={dashboardId} />}
          {widget?.id && <DashboardDropDown widgetId={widget.id} widgetSlug={widget.widgetSlug} />}

          {dashboardId && <span style={{ paddingRight: '20px' }}>/</span>}

          {widget?.name ?? widgetDefinition.name}
        </div>
      }
      level={5}
      actions={
        <>
          {widget && widget?.id && <DuplicateWidgetButton {...props} widget={widget} />}

          {widget?.id && (
            <WidgetSettingsButton
              id={widget.id}
              name={widget.name}
              widgetSlug={widget.widgetSlug}
              description={widget.description}
            />
          )}
          <SaveWidgetButton {...props} />
        </>
      }
    />
  );
}

function parseFormikValuesToFilters(formikObject: {
  [key: string]: {
    key: string;
    value: any;
    operator?: string;
  };
}) {
  const newFilters = Object.values(formikObject).map((formikFilter) => {
    const value = JSON.stringify(formikFilter.value);

    return {
      ...formikFilter,
      value,
    };
  });

  return newFilters;
}

function parseFiltersToFormikValues(
  widgetDefinition: IWidgetDefinition,
  filters?: { key: string; value?: any; operator?: string }[],
) {
  const { fields } = widgetDefinition;
  if (!filters?.length) {
    return {};
  }

  const formikValues = filters.reduce((acc: { [key: string]: any }, filter) => {
    const field = fields?.find((field) => filter.key === field.key);

    if (!field) {
      acc[filter.key] = filter;
    }

    const value = JSON.parse(filter.value);

    acc[filter.key] = {
      ...filter,
      value,
    };

    return acc;
  }, {});

  return formikValues;
}

function WidgetEditorView(props: { widgetDefinition: IWidgetDefinition; widget?: IWidget; widgetSlug?: string }) {
  const { widgetDefinition, widget, widgetSlug } = props;
  const initialValues = parseFiltersToFormikValues(widgetDefinition, widget?.filters);

  /**
   * Filters
   */
  const initialFilters = widget?.filters;
  const [filters, setFilters] = useState<
    {
      key: string;
      operator?: string;
      value?: any;
    }[]
  >(initialFilters);
  const handleChange = (formikValues: any) => {
    const newFilters = parseFormikValuesToFilters(formikValues);

    setFilters(newFilters);
  };

  const settings = filters?.reduce((acc: { [key: string]: any }, filter) => {
    const { key, value } = filter;

    acc[key] = value;

    return acc;
  }, {});

  const [formikContext, setFormikContext] = useState<FormikContextType<any> | undefined>(undefined);
  const handleFormikContext = (ctx: FormikContextType<any>) => setFormikContext(ctx);

  return (
    <Content>
      <WidgetEditorPageHeader
        widget={widget}
        widgetDefinition={widgetDefinition}
        formikContext={formikContext}
        widgetSlug={widgetSlug}
      />
      <WidgetEditorForm
        initialValues={initialValues}
        widgetDefinition={widgetDefinition}
        handleChange={handleChange}
        handleFormikContext={handleFormikContext}
      />
      <WidgetPreview slug={widgetDefinition.slug} settings={settings} filters={filters} />
    </Content>
  );
}

function WidgetEditor(props: { widgetSlug: string; widget?: IWidget }) {
  const { widgetSlug, widget } = props;

  /**
   * grab widget with fields defs.
   */
  const { data, loading } = useQuery<WidgetDefinitionQuery>(WIDGET_DEFINITION, { variables: { slug: widgetSlug } });
  const widgetDefinition = data?.widgetDefinition;

  /**
   * Settings
   */
  // const [settings, setSettings] = useState<any>(initialSettings);
  // const handleSettings = (newSettings: any) => setSettings(newSettings);

  if (widgetDefinition) {
    return <WidgetEditorView widgetDefinition={widgetDefinition} widget={widget} widgetSlug={widgetSlug} />;
  }

  if (loading) {
    return (
      <SpinContainer>
        <Spin tip="Loading Widget">
          <div className="content" />
        </Spin>
      </SpinContainer>
    );
  }

  return <EmptyState>Could not find widget definition</EmptyState>;
}

export default WidgetEditor;
